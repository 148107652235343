import {useWatchdogService} from "../../../services/useWatchdogService";
import {useAuthContext} from "../../../context/auth/authContext";
import * as React from "react";
import {useEffect, useState} from "react";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const ProjectWatchdogControlButton = (props: {}) => {
    const authContext = useAuthContext();
    const watchdogService = useWatchdogService(useAuthContext());
    const [watchdogState, setWatchdogState] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const watchdogName = "UPDATE_PROJETS";

    useEffect(() => {
        getState();
    }, []);

    const getState = () => {
        watchdogService.getWatchdog(watchdogName).then((response) => {
            if (response.status === 200) {
                setWatchdogState(response.data.etat);
                setButtonDisabled(!authContext.hasRole('ROLE_SUPER_ADMIN') || response.data.etat === "ENCOURS" || response.data.etat === "DEMANDE");
            } else {
                setWatchdogState(null);
                setButtonDisabled(true);
            }
        })
        setTimeout(() => {
            getState();
        }, 10000);
    }

    const demandeRun = () => {
        if (!authContext.hasRole('ROLE_SUPER_ADMIN')) {
            return;
        }
        watchdogService.declencheWatchdog(watchdogName).then(() => {
            getState();
        });
    }

    if (!authContext.hasRole('ROLE_ADMIN_PROJETS')) {
        return <></>;
    }

    return (
        <IconButton onClick={() => demandeRun()}
                    disabled={buttonDisabled}
        >
            {watchdogState === null &&
                <Tooltip title="Etat du cron projets inconnu">
                    <HelpOutlineIcon color={'error'}/>
                </Tooltip>
            }
            {watchdogState === "INACTIF" &&
                <Tooltip title="Cron projets inactif">
                    <HourglassEmptyIcon color={'info'}/>
                </Tooltip>
            }
            {watchdogState === "ENCOURS" &&
                <Tooltip title="Cron projets en cours ">
                    <PlayCircleOutlineIcon color={'success'}/>
                </Tooltip>
            }
            {watchdogState === "DEMANDE" &&
                <Tooltip title="Cron projets demandé manuellement">
                    <CloudUploadIcon color={'warning'}/>
                </Tooltip>
            }
        </IconButton>
    )
}

export default ProjectWatchdogControlButton