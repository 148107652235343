import {useApiService} from "./api/useApiService";
import {AuthContextInterface} from "../context/auth/AuthContextInterface";

export const useWatchdogService = (authContext: AuthContextInterface) => {
    const api = useApiService(authContext);

    /**
     * @param clef
     * @return {PromiseAxiosResponse<<WatchdogInterface>>}
     */
    const getWatchdog = async (clef: string) => {
        return api.get('watchdogs/' + clef);
    }

    const declencheWatchdog = async (clef: string) => {
        return api.post('watchdogs/' + clef, {});
    }

    return {
        getWatchdog,
        declencheWatchdog
    }
}
